import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h4 className="privacy-title">Terms and conditions</h4>
            <p className="terms-date"><strong>Last Updated:</strong> [07/02/2025]</p>

            <p>Welcome to the Padmashali Global mobile application ("App"). By using this App, you agree to abide by the Terms and Conditions ("Terms of Use") as set out below.</p>
            <p>These Terms govern your use of our services and content and establish a legal agreement between you and Ro-One Technology Private Limited.</p>

            <ul>
                <li><strong>1. Definitions</strong></li>
                <ul>
                    <li><strong>"We", "Our", "Firm", "Us":</strong> Refers to the Padmashali Global App and its creators.</li>
                    <li><strong>"You", "User", "Your", "Yourself":</strong> Refers to any individual using this App who can enter into legally binding contracts.</li>
                    <li><strong>"Third Parties":</strong> Any entity other than Padmashali Global and the User.</li>
                </ul>

                <li><strong>2. General Terms</strong></li>
                <ul>
                    <li>The section headings in these Terms are for reference only and have no legal effect.</li>
                    <li>These Terms, along with our Privacy Policy, form a binding agreement.</li>
                    <li>By using our App, you accept these Terms. If you disagree, discontinue use immediately.</li>
                    <li>Your continued use of the App signifies acceptance of any changes made.</li>
                </ul>

                <li><strong>3. Application Overview</strong></li>
                <ul>
                    <li>Padmashali Global is an online platform created for members of the Padmashali community.</li>
                    <li>It provides features such as forums, news, events, and other community resources.</li>
                </ul>

                <li><strong>4. Registration</strong></li>
                <ul>
                    <li>Users must provide accurate information, including name, phone number, and other details.</li>
                    <li>False or misleading information may result in account suspension or termination.</li>
                </ul>

                <li><strong>5. Eligibility</strong></li>
                <ul>
                    <li>By using this App, you confirm that you are of legal age.</li>
                    <li>Minors must have parental or legal guardian supervision.</li>
                    <li>You agree to comply with all applicable laws while using the App.</li>
                </ul>

                <li><strong>6. Content</strong></li>
                <ul>
                    <li>All materials in the App, including text, graphics, and images, are either created by us or provided by users.</li>
                    <li>We are not responsible for any inaccuracies or errors in user-generated content.</li>
                    <li>All content is protected by copyright and intellectual property laws.</li>
                    <li>Unauthorized use of content may result in account suspension.</li>
                </ul>

                <li><strong>7. User Responsibilities</strong></li>
                <ul>
                    <li><strong>Account Security:</strong> You are responsible for maintaining the security of your account information.</li>
                    <li><strong>Content Responsibility:</strong> You must ensure that any content you share is accurate and lawful.</li>
                    <li><strong>Prohibited Actions:</strong> Users must not post defamatory, obscene, or illegal content. Violations may result in account termination and legal action.</li>
                </ul>

                <li><strong>8. Amendments and Modifications</strong></li>
                <ul>
                    <li>We reserve the right to update these Terms without prior notice.</li>
                    <li>Continued use after modifications implies acceptance of the new Terms.</li>
                </ul>

                <li><strong>9. Third-Party Services</strong></li>
                <ul>
                    <li>This App may include links to third-party services. We are not responsible for their policies or content.</li>
                </ul>

                <li><strong>10. Intellectual Property</strong></li>
                <ul>
                    <li>All intellectual property rights related to the App and its content belong to Padmashali Global or its licensors.</li>
                    <li>Unauthorized use, reproduction, or distribution is prohibited.</li>
                </ul>

                <li><strong>11. Limitation of Liability</strong></li>
                <ul>
                    <li>We are not liable for any direct, indirect, incidental, or consequential damages arising from:</li>
                    <ul>
                        <li>Use or inability to use the App.</li>
                        <li>Unauthorized access to or alteration of your content.</li>
                        <li>Any content or conduct of a third party on the App.</li>
                    </ul>
                </ul>

                <li><strong>12. Indemnity</strong></li>
                <ul>
                    <li>You agree to indemnify Padmashali Global from any claims or damages arising from:</li>
                    <ul>
                        <li>Your use or misuse of the App.</li>
                        <li>Your violation of these Terms.</li>
                        <li>Any infringement of a third party’s rights.</li>
                    </ul>
                </ul>

                <li><strong>13. Termination</strong></li>
                <ul>
                    <li>We reserve the right to suspend or terminate your account at any time, without notice, for any reason, including violations of these Terms.</li>
                    <li>Termination results in the deletion of all associated content.</li>
                </ul>

                <li><strong>14. Governing Law</strong></li>
                <ul>
                    <li>These Terms are governed by the laws of India.</li>
                    <li>Any disputes shall be subject to the jurisdiction of the courts in Ongole, Andhra Pradesh.</li>
                </ul>

                <li><strong>15. No Refund Policy</strong></li>
                <ul>
                    <li>Donations and account creation fees are non-refundable. All payments are final.</li>
                </ul>

                <li><strong>16. Contact Information</strong></li>
                <ul>
                    <li>If you have questions about these Terms, contact us at:</li>
                    <li><strong>Ro-One Technology Private Limited</strong></li>
                    <li>Gundlapalli, Ongole, Andhra Pradesh</li>
                    <li>Email: padmashaliglobaltrust@gmail.com</li>
                </ul>
            </ul>
        </div>
    );
};

export default TermsAndConditions;
